import { useMemo } from 'react';
import { ascend, sort } from 'ramda';

import {
  useCurrentUserData,
  CurrentUserTenant,
} from 'shared/hooks/useCurrentUserData';

import { useChangeSelectedTenant } from './useTenantSelect.urql.generated';

export function useTenantSelect(
  tenantsType: 'managed-tenants' | 'tenants-with-access',
  urlAfterSelect?: string,
) {
  const [{ data, fetching }] = useCurrentUserData();
  const [changeSelectedTenantState, changeSelectedTenant] =
    useChangeSelectedTenant();

  const tenants = useMemo(
    () =>
      sort(
        ascend((x) => x.name.toLowerCase()),
        (tenantsType === 'managed-tenants'
          ? data?.managedTenants
          : data?.tenants) ?? [],
      ),
    [data, tenantsType],
  );
  const selectedTenantId = data?.currentUser.selectedTenantId;

  const isSelectDisabled =
    tenants.length === 0 || fetching || changeSelectedTenantState.fetching;

  const handleTenantSelect = (tenant: Pick<CurrentUserTenant, 'id'>) => {
    changeSelectedTenant({ tenantId: tenant.id }).then(() => {
      window.location.replace(urlAfterSelect ?? window.location.href);
    });
  };

  return {
    isSelectDisabled,
    handleTenantSelect,
    areTenantsLoading: fetching,
    tenants,
    selectedTenantId,
  };
}

export function useCurrentTenant() {
  const [{ data }] = useCurrentUserData();

  const selectedTenantId = data?.currentUser.selectedTenantId;

  return (
    data?.tenants?.find((x) => x.id === selectedTenantId) ??
    data?.managedTenants.find((x) => x.id === selectedTenantId)
  );
}
