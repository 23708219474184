import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GenresPageDataVariables = Types.Exact<{ [key: string]: never; }>;


export type GenresPageData = { __typename?: 'Query', genres: Array<{ __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string }> };

export type GenresPageGenre = { __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string };

export type CreateGenreVariables = Types.Exact<{
  input: Types.AddGenreInput;
}>;


export type CreateGenre = { __typename?: 'Mutation', addGenre: { __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string } };

export type UpdateGenreVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateGenreInput;
}>;


export type UpdateGenre = { __typename?: 'Mutation', updateGenre: { __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string } };

export type DeleteGenreVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteGenre = { __typename?: 'Mutation', deleteGenre: boolean };

export const GenresPageGenre = gql`
    fragment GenresPageGenre on GenreModel {
  id
  name
  color
  logoUrl
}
    `;
export const GenresPageDataDocument = gql`
    query GenresPageData {
  genres {
    ...GenresPageGenre
  }
}
    ${GenresPageGenre}`;

export function useGenresPageData(options?: Omit<Urql.UseQueryArgs<GenresPageDataVariables>, 'query'>) {
  return Urql.useQuery<GenresPageData, GenresPageDataVariables>({ query: GenresPageDataDocument, ...options });
};
export const CreateGenreDocument = gql`
    mutation CreateGenre($input: AddGenreInput!) {
  addGenre(input: $input) {
    ...GenresPageGenre
  }
}
    ${GenresPageGenre}`;

export function useCreateGenre() {
  return Urql.useMutation<CreateGenre, CreateGenreVariables>(CreateGenreDocument);
};
export const UpdateGenreDocument = gql`
    mutation UpdateGenre($id: UUID!, $input: UpdateGenreInput!) {
  updateGenre(id: $id, input: $input) {
    ...GenresPageGenre
  }
}
    ${GenresPageGenre}`;

export function useUpdateGenre() {
  return Urql.useMutation<UpdateGenre, UpdateGenreVariables>(UpdateGenreDocument);
};
export const DeleteGenreDocument = gql`
    mutation DeleteGenre($id: UUID!) {
  deleteGenre(id: $id)
}
    `;

export function useDeleteGenre() {
  return Urql.useMutation<DeleteGenre, DeleteGenreVariables>(DeleteGenreDocument);
};