import {
  AccountInfo,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { logout } from './auth';

export function AuthProvider(
  props: React.PropsWithChildren<{ msalInstance: PublicClientApplication }>,
) {
  const { children, msalInstance } = props;

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}

export function useAuthAPI() {
  const { inProgress, accounts } = useMsal();

  // don't rely on useIsAuthenticated because it's always false on 1st render
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2846
  const isAuthenticated = accounts.length > 0;
  const isLoggingOut = inProgress === InteractionStatus.Logout;
  const isReturnedFromRedirect =
    inProgress === InteractionStatus.HandleRedirect;

  return {
    logout,
    isAuthenticated,
    isLoggingOut,
    isReturnedFromRedirect,
  };
}

export function useAuth() {
  const msal = useMsal();
  const api = useAuthAPI();

  if (msal === undefined || api === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return { msal, ...api };
}

export function useUserName() {
  const {
    msal: { accounts },
  } = useAuth();

  const userName = extractUserName(accounts);

  const [lastExistingUserName, setLastExistingUserName] = useState(userName);

  useEffect(() => {
    if (userName) {
      setLastExistingUserName(userName);
    }
  }, [userName]);

  return lastExistingUserName;
}

export function extractUserName(accounts: AccountInfo[]) {
  const tokenClaims = accounts[0]?.idTokenClaims as
    | { name: string }
    | undefined;
  const userName = tokenClaims?.name;

  return userName;
}
