import { Cache } from '@urql/exchange-graphcache';

import { Query } from 'serverCache/schema.types';

export function indicateCacheMiss(): string {
  // it's fine to return undefined in resolvers to indicate a cache miss, but the urql codegen doesn't understand it (yet)
  // https://formidable.com/open-source/urql/docs/graphcache/local-resolvers/ ...while returning undefined will cause a cache miss.
  return undefined as unknown as string;
}

export function invalidatePaginatedQuery(cache: Cache, query: keyof Query) {
  cache
    .inspectFields('Query')
    .filter((x) => x.fieldName === query)
    .forEach((queryField) => {
      const connectionEntityKey = cache.resolve(
        'Query',
        queryField.fieldName,
        queryField.arguments,
      ) as string;

      cache.inspectFields(connectionEntityKey).forEach((x) => {
        cache.invalidate(connectionEntityKey, x.fieldName, x.arguments);
      });
    });
}
