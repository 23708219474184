import { stringifyVariables } from 'urql';
import { Cache, Variables } from '@urql/exchange-graphcache';

import { ProductPreviewsVariables } from 'pages/AdminPage/DraftProductsPage/CreateOrEditProduct/ProductPreviews/ProductPreviews.urql.generated';
import { TemplatePreviewsVariables } from 'pages/AdminPage/TemplatesPage/CreateOrEditTemplate/TemplatePreviews/TemplatePreviews.urql.generated';

import { ProductPreviewsFiltersInput, Query } from '../../schema.types';

type PreviewsQueryArgs =
  | {
      type: keyof Pick<Query, 'productPreviews'>;
      variables: ProductPreviewsVariables;
    }
  | {
      type: keyof Pick<Query, 'templatePreviews'>;
      variables: TemplatePreviewsVariables;
    };

export type WithPreviewsQueryArgs<T> = T & {
  previewsQueryArgs: PreviewsQueryArgs;
};

export function invalidateAllPreviewsExceptQuery(
  cache: Cache,
  variables: WithPreviewsQueryArgs<Variables>,
) {
  cache
    .inspectFields('Query')
    .filter((x) => x.fieldName === variables.previewsQueryArgs.type)
    .forEach((queryField) => {
      const connectionEntityKey = cache.resolve(
        'Query',
        queryField.fieldName,
        queryField.arguments,
      ) as string;

      if (
        stringifyVariables(
          (
            queryField.arguments as {
              filters: ProductPreviewsFiltersInput;
            }
          ).filters,
        ) !== stringifyVariables(variables.previewsQueryArgs.variables.filters)
      ) {
        cache.inspectFields(connectionEntityKey).forEach((x) => {
          cache.invalidate(connectionEntityKey, x.fieldName, x.arguments);
        });
      }
    });
}
