import { forwardRef, PropsWithChildren } from 'react';
import cx from 'clsx';

import { ClassesProp } from 'shared/types';

export type Theme = 'light' | 'dark';

const createThemeClass = (theme: Theme) => `theme-${theme}`;

type Props = PropsWithChildren<{
  theme: Theme;
}> &
  ClassesProp<'root'>;

const WITH_THEME_CLASSES = {
  root: 'grip-theme-wrapper',
  light: `light`,
  dark: `dark`,
};

export const WithTheme = forwardRef<HTMLDivElement | null, Props>(
  (props: Props, ref) => {
    const { children, theme, classes } = props;

    return (
      <div
        className={cx(
          createThemeClass(theme),
          WITH_THEME_CLASSES.root,
          WITH_THEME_CLASSES[theme],
          classes?.root,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

WithTheme.displayName = 'WithTheme';

// used for Components with portal
// to allow using theme mixins in their scss
export function getThemeWrapperElementForPortal(theme?: Theme) {
  if (theme) {
    const el =
      theme === 'dark'
        ? getThemeWrapperElementDark()
        : getThemeWrapperElementLight();

    if (el) {
      return el;
    }
  }

  // if theme is not specified, try to use the first WithTheme available
  // in most cases in the app only 1 WithTheme wrapper should exist, so
  // passing theme there explicitly is not mandatory
  return getThemeWrapperElement() ?? document.body;
}

function getThemeWrapperElement() {
  return document.querySelector(`.${WITH_THEME_CLASSES.root}`);
}

function getThemeWrapperElementDark() {
  return document.querySelector(
    `.${WITH_THEME_CLASSES.root}.${WITH_THEME_CLASSES.dark}`,
  );
}

function getThemeWrapperElementLight() {
  return document.querySelector(
    `.${WITH_THEME_CLASSES.root}.${WITH_THEME_CLASSES.light}`,
  );
}
