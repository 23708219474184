import * as Types from '../../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChangeSelectedTenantVariables = Types.Exact<{
  tenantId: Types.Scalars['UUID'];
}>;


export type ChangeSelectedTenant = { __typename?: 'Mutation', changeTenantView: { __typename?: 'CurrentUserModel', id: string } };


export const ChangeSelectedTenantDocument = gql`
    mutation ChangeSelectedTenant($tenantId: UUID!) {
  changeTenantView(tenantId: $tenantId) {
    id
  }
}
    `;

export function useChangeSelectedTenant() {
  return Urql.useMutation<ChangeSelectedTenant, ChangeSelectedTenantVariables>(ChangeSelectedTenantDocument);
};