import { ButtonHTMLAttributes, useState } from 'react';
import cx from 'clsx';

import { ClassesProp } from 'shared/types';
import { QALocatorsProp } from 'aspects/qaLocators';
import { HelpText, TooltipTheme } from 'shared/components/HelpText/HelpText';

import styles from './Button.module.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  color: 'grey' | 'purple' | 'orange' | 'animated-gradient';
  description?: {
    text: string;
    tooltipTheme: TooltipTheme;
  };
  icon?: React.ReactNode;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'big';
  shouldHaveHeightByContent?: boolean;
  particlesExplosionAnimation?: 'purple' | 'purple-and-orange';
} & ClassesProp<'root'> &
  QALocatorsProp<'root'>;

export function Button(props: Props) {
  const {
    className,
    color,
    size = 'big',
    isLoading,
    children,
    disabled,
    icon,
    description,
    shouldHaveHeightByContent,
    qaLocators,
    particlesExplosionAnimation,
    ...rest
  } = props;

  const [shouldAnimateParticlesExplosion, setShouldAnimateParticlesExplosion] =
    useState(false);

  const button = (
    <button
      className={cx(
        styles.button,
        styles[size],
        styles[color],
        isLoading && styles.isLoading,
        shouldHaveHeightByContent && styles.heightByContent,
        className,
      )}
      type="button"
      disabled={isLoading || disabled}
      {...qaLocators?.root}
      {...rest}
      onClick={(event) => {
        if (particlesExplosionAnimation) {
          setShouldAnimateParticlesExplosion(true);
          setTimeout(() => {
            setShouldAnimateParticlesExplosion(false);
          }, 700);
        }
        rest.onClick?.(event);
      }}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
      {description && (
        <div className={styles.description}>
          <HelpText
            text={description.text}
            tooltipTheme={description.tooltipTheme}
            tooltipOffsetY={10}
          />
        </div>
      )}
    </button>
  );

  // particlesExplosion needs a separate wrapper because there is a case when .particlesExplosion and .loading need to happen at the same time and because both of them use pseudo-classes they might overwrite each other's styles
  return particlesExplosionAnimation ? (
    <div
      className={cx(
        styles.particlesExplosion,
        styles[particlesExplosionAnimation],
        shouldAnimateParticlesExplosion && styles.animate,
      )}
    >
      {button}
    </div>
  ) : (
    button
  );
}

export type { Props as ButtonProps };
