import { createBrowserHistory, History } from 'history';

import { ROUTES } from 'pages/shared/routes';
import { TENANT_ID_URL_PARAM } from 'pages/shared/components/GlobalAutoShownOverlayContent/SwitchTenantModal/SwitchTenantModal';

export const routerHistory = patchHistoryForAddingTenantIdInUrl(
  createBrowserHistory(),
);

export function patchHistoryForAddingTenantIdInUrl<
  T extends Pick<History<unknown>, 'push' | 'replace'>,
>(history: T) {
  const patchedHistory = new Proxy(history, {
    get(target, prop, receiver) {
      if (prop === 'push' || prop === 'replace') {
        return patchMethodForAddingTenantIdInUrl(target[prop]);
      }

      return Reflect.get(target, prop, receiver);
    },
  });

  return patchedHistory;
}

function patchMethodForAddingTenantIdInUrl(
  originalMethod: History<unknown>['push'] | History<unknown>['replace'],
): typeof originalMethod {
  return function methodWithAddedTenantIdInUrl(
    // https://www.typescriptlang.org/docs/handbook/2/functions.html#declaring-this-in-a-function
    this: History<unknown> | undefined,
    ...args
  ) {
    const currentUrlTenantId = new URLSearchParams(window.location.search).get(
      TENANT_ID_URL_PARAM,
    );

    // if string path is passed to push
    if (typeof args[0] === 'string' && !args[0].includes(ROUTES.login)) {
      const path = args[0];
      const [pathUrl, pathSearch] = path.split('?');
      const pathUrlParams = new URLSearchParams(pathSearch);
      const pathTenantId = pathUrlParams.get(TENANT_ID_URL_PARAM);

      // if path doesn't already include tenantId, append current tenantId to path
      if (currentUrlTenantId && !pathTenantId) {
        pathUrlParams.set(TENANT_ID_URL_PARAM, currentUrlTenantId);
        const newPath = `${pathUrl}?${pathUrlParams.toString()}`;
        args.splice(0, 1, newPath);
      }
    } else if (
      typeof args[0] !== 'string' &&
      !args[0].pathname?.includes(ROUTES.login)
    ) {
      const location = args[0];
      const locationUrlParams = new URLSearchParams(location.search);
      const locationTenantId = locationUrlParams.get(TENANT_ID_URL_PARAM);

      // if location doesn't already include tenantId, append current tenantId to location's search
      if (currentUrlTenantId && !locationTenantId) {
        locationUrlParams.set(TENANT_ID_URL_PARAM, currentUrlTenantId);
        const newLocation: typeof location = {
          ...location,
          search: locationUrlParams.toString(),
        };
        args.splice(0, 1, newLocation);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalMethod.apply(this, args as any);
  };
}
