import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProvisioningListsVariables = Types.Exact<{ [key: string]: never; }>;


export type ProvisioningLists = { __typename?: 'Query', userProvisioningTables: Array<{ __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean }>, createdBy: { __typename?: 'UserModel', id: string, email: string } }> };

export type ProvisioningListByIdVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type ProvisioningListById = { __typename?: 'Query', userProvisioningTableById: { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean, processedAt?: string | null, budgets: Array<{ __typename?: 'BudgetModel', id: string, name: string }>, productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string }> }>, createdBy: { __typename?: 'UserModel', id: string, email: string } } };

export type ProvisioningListOverview = { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean }>, createdBy: { __typename?: 'UserModel', id: string, email: string } };

export type ProvisioningList = { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean, processedAt?: string | null, budgets: Array<{ __typename?: 'BudgetModel', id: string, name: string }>, productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string }> }>, createdBy: { __typename?: 'UserModel', id: string, email: string } };

export type AddProvisioningListVariables = Types.Exact<{
  input: Types.AddUserProvisioningTableInput;
}>;


export type AddProvisioningList = { __typename?: 'Mutation', addUserProvisioningTable: { __typename: 'AddUserProvisioningTableFailed', csvDownloadUrl?: string | null } | { __typename: 'AddUserProvisioningTableSucceeded', data: { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean, processedAt?: string | null, budgets: Array<{ __typename?: 'BudgetModel', id: string, name: string }>, productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string }> }>, createdBy: { __typename?: 'UserModel', id: string, email: string } } } };

export type UpdateProvisioningListVariables = Types.Exact<{
  input: Types.UpdateUserProvisioningTableInput;
}>;


export type UpdateProvisioningList = { __typename?: 'Mutation', updateUserProvisioningTable: { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean, processedAt?: string | null, budgets: Array<{ __typename?: 'BudgetModel', id: string, name: string }>, productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string }> }>, createdBy: { __typename?: 'UserModel', id: string, email: string } } };

export type DeleteProvisioningListVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteProvisioningList = { __typename?: 'Mutation', removeUserProvisioningTable: boolean };

export const ProvisioningListOverview = gql`
    fragment ProvisioningListOverview on UserProvisioningTableModel {
  id
  name
  items {
    email
    processed
  }
  createdAt
  createdBy {
    id
    email
  }
}
    `;
export const ProvisioningList = gql`
    fragment ProvisioningList on UserProvisioningTableModel {
  ...ProvisioningListOverview
  items {
    budgets {
      id
      name
    }
    email
    processed
    processedAt
    productGroups {
      id
      name
    }
  }
}
    ${ProvisioningListOverview}`;
export const ProvisioningListsDocument = gql`
    query ProvisioningLists {
  userProvisioningTables {
    ...ProvisioningListOverview
  }
}
    ${ProvisioningListOverview}`;

export function useProvisioningLists(options?: Omit<Urql.UseQueryArgs<ProvisioningListsVariables>, 'query'>) {
  return Urql.useQuery<ProvisioningLists, ProvisioningListsVariables>({ query: ProvisioningListsDocument, ...options });
};
export const ProvisioningListByIdDocument = gql`
    query ProvisioningListById($id: UUID!) {
  userProvisioningTableById(id: $id) {
    ...ProvisioningList
  }
}
    ${ProvisioningList}`;

export function useProvisioningListById(options: Omit<Urql.UseQueryArgs<ProvisioningListByIdVariables>, 'query'>) {
  return Urql.useQuery<ProvisioningListById, ProvisioningListByIdVariables>({ query: ProvisioningListByIdDocument, ...options });
};
export const AddProvisioningListDocument = gql`
    mutation AddProvisioningList($input: AddUserProvisioningTableInput!) {
  addUserProvisioningTable(input: $input) {
    __typename
    ... on AddUserProvisioningTableSucceeded {
      data {
        ...ProvisioningList
      }
    }
    ... on AddUserProvisioningTableFailed {
      csvDownloadUrl
    }
  }
}
    ${ProvisioningList}`;

export function useAddProvisioningList() {
  return Urql.useMutation<AddProvisioningList, AddProvisioningListVariables>(AddProvisioningListDocument);
};
export const UpdateProvisioningListDocument = gql`
    mutation UpdateProvisioningList($input: UpdateUserProvisioningTableInput!) {
  updateUserProvisioningTable(input: $input) {
    ...ProvisioningList
  }
}
    ${ProvisioningList}`;

export function useUpdateProvisioningList() {
  return Urql.useMutation<UpdateProvisioningList, UpdateProvisioningListVariables>(UpdateProvisioningListDocument);
};
export const DeleteProvisioningListDocument = gql`
    mutation DeleteProvisioningList($id: UUID!) {
  removeUserProvisioningTable(id: $id)
}
    `;

export function useDeleteProvisioningList() {
  return Urql.useMutation<DeleteProvisioningList, DeleteProvisioningListVariables>(DeleteProvisioningListDocument);
};