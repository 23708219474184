import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'resize-observer-polyfill';
import { PiFlagPennantFill as FlagIcon } from 'react-icons/pi';

import { useTenantSelect } from 'pages/shared/hooks/useTenantSelect/useTenantSelect';
import { useCurrentUserData } from 'shared/hooks/useCurrentUserData';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { NewModal } from 'shared/components/Modal/NewModal/NewModal';
import { ROUTES } from 'pages/shared/routes';
import { TenantModel } from 'serverCache/schema.types';
import { setupClient } from 'serverCache/client/graphQLClient';

import styles from './SwitchTenantModal.module.scss';
import {
  SelectedTenant,
  SelectedTenantDocument,
} from './SwitchTenantModal.urql.generated';

export const TENANT_ID_URL_PARAM = 'tenantId';

export function useSwitchTenantModal() {
  const { selectedTenant, urlSelectedTenant, urlSelectedTenantId } =
    useSelectedTenant();

  const [wasModalShown, setWasModalShown] = useState(false);
  const history = useHistory();
  const closeModal = () => {
    setWasModalShown(true);
    history.push(`${ROUTES.main}?${TENANT_ID_URL_PARAM}=${selectedTenant?.id}`);
  };

  // init URL with selected tenant
  useEffect(() => {
    if (selectedTenant && !urlSelectedTenantId) {
      const paramsWithSelectedTenant = new URLSearchParams(
        history.location.search,
      );
      paramsWithSelectedTenant.set(TENANT_ID_URL_PARAM, selectedTenant.id);
      history.replace({
        ...history.location,
        search: paramsWithSelectedTenant.toString(),
      });
    }
  }, [selectedTenant, urlSelectedTenantId, history]);

  // if at some point tenant in URL is changed (e.g. when using browser back/forward buttons)
  // show modal again if needed
  useEffect(() => {
    if (wasModalShown) {
      setWasModalShown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSelectedTenantId]);

  const tenantWasChangedInOtherTabModal = useTenantWasChangedInOtherTabModal();

  if (tenantWasChangedInOtherTabModal) {
    return tenantWasChangedInOtherTabModal;
  }

  if (!wasModalShown) {
    const cannotFindUrlTenant = Boolean(
      selectedTenant && urlSelectedTenantId && !urlSelectedTenant,
    );

    if (cannotFindUrlTenant) {
      return (
        <CannotSwitchTenantModal isOpen={!wasModalShown} onClose={closeModal} />
      );
    }

    const isWrongTenantSelected = Boolean(
      selectedTenant &&
        urlSelectedTenant &&
        selectedTenant.id !== urlSelectedTenant.id,
    );

    if (isWrongTenantSelected) {
      return <SwitchTenantModal isOpen={!wasModalShown} onClose={closeModal} />;
    }
  }

  return null;
}

type TenantShort = Pick<TenantModel, 'id' | 'name'>;

function useTenantWasChangedInOtherTabModal() {
  const [latestSelectedTenant, setLatestSelectedTenant] =
    useState<TenantShort>();

  // when user gets back to the browser tab, fetch latest selected tenant
  // and compare it with tab's tenant
  useEffect(() => {
    const checkIfSelectedTenantChanged = () => {
      if (!document.hidden) {
        // don't refetch useCurrentUserData hook
        // 1. to keep the app intact (e.g. tenant select's UI) and avoid confusing users
        // 2. to fetch only the data which is needed for logic here and not more
        // create a new client to make requests independently from cache
        // https://github.com/urql-graphql/urql/issues/2776#issuecomment-1300570976
        // otherwise the fetched data is written to cache and useCurrentUserData hook is updated
        setupClient()
          .query<SelectedTenant>(
            SelectedTenantDocument,
            {},
            { requestPolicy: 'network-only' },
          )
          .then((data) => {
            const selectedTenant = data.data?.tenants.find(
              (x) => x.id === data.data?.currentUser.selectedTenantId,
            );

            if (selectedTenant) {
              setLatestSelectedTenant(selectedTenant);
            }
          });
      }
    };
    document.addEventListener('visibilitychange', checkIfSelectedTenantChanged);

    return () =>
      document.removeEventListener(
        'visibilitychange',
        checkIfSelectedTenantChanged,
      );
  }, []);

  const { selectedTenant: currentTabSelectedTenant } = useSelectedTenant();

  return (
    currentTabSelectedTenant &&
    latestSelectedTenant &&
    currentTabSelectedTenant.id !== latestSelectedTenant.id && (
      <TenantWasChangedInOtherTabModal
        latestSelectedTenant={latestSelectedTenant}
        currentTabSelectedTenant={currentTabSelectedTenant}
      />
    )
  );
}

const RELOAD_APP_BUTTON_LABEL = 'go to main page';
const SWITCH_TENANT_BACK_BUTTON_LABEL = 'switch back';

type TenantWasChangedInOtherTabModalProps = {
  currentTabSelectedTenant: TenantShort;
  latestSelectedTenant: TenantShort;
};

function TenantWasChangedInOtherTabModal(
  props: TenantWasChangedInOtherTabModalProps,
) {
  const { currentTabSelectedTenant, latestSelectedTenant } = props;
  const { handleTenantSelect } = useTenantSelect('tenants-with-access');

  const switchTenantBack = () => {
    handleTenantSelect(currentTabSelectedTenant);
  };

  const reloadAppFromIS = () => {
    window.location.replace(ROUTES.main);
  };

  return (
    <NewModal
      isOpen
      buttons={[
        {
          text: RELOAD_APP_BUTTON_LABEL,
          onClick: reloadAppFromIS,
          className: styles.tenantWasChangedButton,
        },
        {
          text: SWITCH_TENANT_BACK_BUTTON_LABEL,
          onClick: switchTenantBack,
          className: styles.tenantWasChangedButton,
        },
      ]}
      icon={<FlagIcon />}
      title="Your current tenant has changed"
      contentClass={styles.tenantWasChangedContent}
      containerClass={styles.tenantWasChangedContainer}
    >
      <NewModal.Text
        textParts={[
          {
            text: `Would you like to `,
          },
          {
            text: `${RELOAD_APP_BUTTON_LABEL} as ${latestSelectedTenant.name}`,
            isBold: true,
          },
          { text: '? Or ' },
          {
            text: `${SWITCH_TENANT_BACK_BUTTON_LABEL} to ${currentTabSelectedTenant.name} and continue on this page`,
            isBold: true,
          },
          {
            text: '?',
          },
        ]}
      />
    </NewModal>
  );
}

type ModalProps = {
  isOpen: boolean;
  onClose(): void;
};

function SwitchTenantModal(props: ModalProps) {
  const { isOpen, onClose } = props;

  const { handleTenantSelect } = useTenantSelect('tenants-with-access');
  const [isSwitchingTenant, setIsSwitchingTenant] = useState(false);

  const { urlSelectedTenant } = useSelectedTenant();

  const handleClose = () => {
    if (!isSwitchingTenant) {
      onClose();
    }
  };

  const handleProceed = () => {
    if (urlSelectedTenant) {
      setIsSwitchingTenant(true);
      handleTenantSelect(urlSelectedTenant);
    }
  };

  return (
    urlSelectedTenant && (
      <NewModal
        isOpen={isOpen}
        onClose={handleClose}
        buttons={[
          { text: 'Cancel', onClick: handleClose },
          {
            text: 'Proceed',
            isLoading: isSwitchingTenant,
            onClick: handleProceed,
          },
        ]}
        icon={<FlagIcon />}
        title={`Switch to ${urlSelectedTenant.name}?`}
        contentClass={styles.switchTenantContent}
      >
        <NewModal.Text
          textParts={[
            {
              text: 'To view the specified content, we will need to switch you over to another tenant.',
            },
          ]}
        />
      </NewModal>
    )
  );
}

function CannotSwitchTenantModal(props: ModalProps) {
  const { isOpen, onClose } = props;

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Sorry!"
      icon={<FlagIcon />}
      buttons={[
        {
          text: 'Got it',
          onClick: onClose,
        },
      ]}
      contentClass={styles.cannotSwitchTenantContent}
    >
      <NewModal.Text
        textParts={[
          {
            text: 'The tenant specified in the URL is not valid. Do check your link and if the issue persists, please contact your PM.',
          },
        ]}
      />
    </NewModal>
  );
}

function useSelectedTenant() {
  const [{ data }] = useCurrentUserData();

  const tenants = data?.tenants ?? [];
  const urlSelectedTenantId = useSearchParams().get(TENANT_ID_URL_PARAM);
  const urlSelectedTenant = urlSelectedTenantId
    ? tenants.find((x) => x.id === urlSelectedTenantId)
    : undefined;
  const selectedTenant = tenants.find(
    (x) => x.id === data?.currentUser.selectedTenantId,
  );

  return { selectedTenant, urlSelectedTenant, urlSelectedTenantId };
}
