import {
  Client,
  fetchExchange,
  makeOperation,
  mapExchange,
  Provider,
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { useMemo } from 'react';

import { acquireAccessToken } from 'features/auth';

import { GraphCacheConfig } from '../schema.types';
import { API_URL } from '../constants';
import { cacheExchangeOptions } from './cacheExchange/cacheExchangeOptions';

export { getUrqlOutputCombinationsCacheIDsByHashKey } from './cacheExchange/outputCombinations';

export function setupClient() {
  return new Client({
    url: API_URL,
    exchanges: [
      // devtoolsExchange // commented by default because it adds to the critical amount of work on the main thread so enable only when you need to debug something
      cacheExchange<Omit<GraphCacheConfig, 'storage'>>(cacheExchangeOptions),
      mapExchange({
        // https://github.com/urql-graphql/urql/issues/234#issuecomment-1752090469
        async onOperation(operation) {
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...operation.context.fetchOptions,
              headers: {
                ...(operation.context.fetchOption?.headers ?? {}),
                authorization: `Bearer ${await acquireAccessToken().catch(
                  window.console.log,
                )}`,
              },
            },
          });
        },
      }),
      fetchExchange,
    ],
  });
}

export function URQLGraphQLProvider({ children }: React.PropsWithChildren) {
  const client = useMemo(() => setupClient(), []);
  return <Provider value={client}>{children}</Provider>;
}
