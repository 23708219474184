import * as Types from '../../../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SelectedTenantVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectedTenant = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUserModel', selectedTenantId: string }, tenants: Array<{ __typename?: 'TenantModel', id: string, name: string }> };


export const SelectedTenantDocument = gql`
    query SelectedTenant {
  currentUser {
    selectedTenantId
  }
  tenants {
    id
    name
  }
}
    `;

export function useSelectedTenant(options?: Omit<Urql.UseQueryArgs<SelectedTenantVariables>, 'query'>) {
  return Urql.useQuery<SelectedTenant, SelectedTenantVariables>({ query: SelectedTenantDocument, ...options });
};