import { gql } from 'urql';
import { Cache } from '@urql/exchange-graphcache';

import {
  OrderItemModel,
  OrderItemStatus,
  OrderModel,
  OutputCombinationModel,
  OutputCombinationOrderInfo,
  OutputCombinationStatus,
} from '../../schema.types';
import { urqlOutputCombinationsHashKeysToIDsMap } from './outputCombinations';
import {
  _MarkOutputCombinationOrderedCombination,
  _MarkViewerCombinationOrderedCombination,
  _OutputCombinationWithGrouping,
  _UpdateCombinationWithGroupingAfterOrder,
  _UpdateCombinationWithoutGroupingAfterOrder,
  _DeductBudgetCreditsBudget,
} from './orders.urql.generated';

export function updateOnOrder({
  cache,
  budgetId,
  orderItems,
  orderCost,
}: {
  cache: Cache;
  budgetId: string;
  orderCost: number;
  orderItems?: OrderItemModel[];
  wasOrderedWithPremium: boolean;
}) {
  markOutputCombinationsAsOrdered({
    orderItems: orderItems ?? [],
    cache,
  });

  deductBudgetCredits({ cache, budgetId, orderCost });

  cache
    .inspectFields('Query')
    .filter((x) => x.fieldName === 'currentBasket' || x.fieldName === 'budgets')
    .forEach((x) => cache.invalidate('Query', x.fieldKey));
}

function deductBudgetCredits({
  cache,
  budgetId,
  orderCost,
}: {
  cache: Cache;
  budgetId: string;
  orderCost: number;
}) {
  const budgetFragment = gql`
    fragment _DeductBudgetCreditsBudget on BudgetModel {
      id
      credits
      premiumOrdering {
        enabled
        multiplier
      }
    }
  `;
  const currentBudget = cache.readFragment<_DeductBudgetCreditsBudget>(
    budgetFragment,
    cache.keyOfEntity({
      __typename: 'BudgetModel',
      id: budgetId,
    }) as string,
  );

  if (currentBudget) {
    cache.writeFragment<_DeductBudgetCreditsBudget>(budgetFragment, {
      id: budgetId,
      credits: currentBudget.credits - orderCost,
      premiumOrdering: currentBudget.premiumOrdering,
    });
  }
}

export function markOutputCombinationsAsOrdered(params: {
  orderItems: OrderModel['orderItems'];
  cache: Cache;
}) {
  const { orderItems, cache } = params;

  const allOutputCombinations = Object.values(
    urqlOutputCombinationsHashKeysToIDsMap,
  )
    .flatMap((x) => x)
    .map((x) =>
      // make sure to read only fields that exist on every page of IS
      // e.g. don't request groupingInfo because it exists only on IS pages with grouping
      // because readFragment returns null for data which doesn't have all fields of the fragment
      cache.readFragment<_MarkOutputCombinationOrderedCombination>(
        gql`
          fragment _MarkOutputCombinationOrderedCombination on OutputCombinationModel {
            id
            mainProductId
            template {
              id
            }
            contentKit {
              id
            }
            status
          }
        `,
        {
          id: x,
          __typename: 'OutputCombinationModel',
        } as unknown as OutputCombinationModel,
      ),
    )
    .filter(Boolean) as _MarkOutputCombinationOrderedCombination[];

  type OrderItem = Pick<OrderItemModel, 'contentKitId' | 'mainProductId'> & {
    template: Pick<OrderItemModel['template'], 'id'>;
  };
  function isOrderItemFromGroup(
    orderItem: OrderItem,
    group: {
      productId: string;
      contentKitId: string;
      templateId: string;
    },
  ) {
    return (
      orderItem.template.id === group.templateId &&
      orderItem.contentKitId === group.contentKitId &&
      orderItem.mainProductId === group.productId
    );
  }

  orderItems.forEach((x) => {
    markOutputCombinationOrdered(x);
    markViewerCombinationOrdered(x);
  });

  function markOutputCombinationOrdered(
    orderItem: OrderModel['orderItems'][0],
  ) {
    // for ordered basket item, find combination:
    // 1. which is not ordered
    // 2. with same group as basket item
    // (the basket item can't be a combination from grouping, because they are already ordered)
    const combinationWithSameGroup = allOutputCombinations.find(
      (x) =>
        x.status === OutputCombinationStatus.Preview &&
        isOrderItemFromGroup(orderItem, {
          templateId: x.template.id,
          contentKitId: x.contentKit.id,
          productId: x.mainProductId,
        }),
    );

    if (combinationWithSameGroup) {
      const outputCombinationWithGrouping =
        cache.readFragment<_OutputCombinationWithGrouping>(
          gql`
            fragment _OutputCombinationWithGrouping on OutputCombinationModel {
              id
              status
              template {
                id
              }
              contentKit {
                id
              }
              mainProductId
              groupingInfo {
                completedCount
                inProgressCount
                contentKitId
                productId
                templateId
                totalCount
              }
            }
          `,
          {
            id: combinationWithSameGroup.id,
            __typename: 'OutputCombinationModel',
          },
        );

      // update grouping counters for regular IS so that grouping icon is displayed
      if (outputCombinationWithGrouping) {
        cache.writeFragment<_UpdateCombinationWithGroupingAfterOrder>(
          gql`
            fragment _UpdateCombinationWithGroupingAfterOrder on OutputCombinationModel {
              id
              groupingInfo {
                completedCount
                inProgressCount
                contentKitId
                productId
                templateId
                totalCount
              }
            }
          `,
          {
            __typename: 'OutputCombinationModel',
            id: combinationWithSameGroup.id,
            groupingInfo: {
              ...outputCombinationWithGrouping.groupingInfo,
              __typename: 'OutputCombinationGroupingInfoModel',
              completedCount:
                outputCombinationWithGrouping?.groupingInfo.completedCount ?? 0,
              totalCount:
                (outputCombinationWithGrouping?.groupingInfo.totalCount ?? 0) +
                1,
              inProgressCount:
                (outputCombinationWithGrouping?.groupingInfo.inProgressCount ??
                  0) + 1,
            },
          },
        );
      } else {
        // update combinations without grouping info / feature, as on SeeAll page
        cache.writeFragment<_UpdateCombinationWithoutGroupingAfterOrder>(
          gql`
            fragment _UpdateCombinationWithoutGroupingAfterOrder on OutputCombinationModel {
              id
              status
            }
          `,
          {
            __typename: 'OutputCombinationModel',
            id: combinationWithSameGroup.id,
            status: OutputCombinationStatus.InProgress,
          },
        );
      }
    }
  }

  function markViewerCombinationOrdered(
    orderItem: OrderModel['orderItems'][0],
  ) {
    const fragment = gql`
      fragment _MarkViewerCombinationOrderedCombination on EditorCombinationDetailsModel {
        hashKey
        orderHistory {
          downloadUrl
          preview {
            url
            width
          }
          orderedAt
          templateVersion
          status
        }
      }
    `;

    const viewerCombinationDetailsData =
      cache.readFragment<_MarkViewerCombinationOrderedCombination>(fragment, {
        __typename: 'EditorCombinationDetailsModel',
        hashKey: orderItem.basketHashKey,
      });

    if (viewerCombinationDetailsData) {
      cache.writeFragment<_MarkViewerCombinationOrderedCombination>(fragment, {
        hashKey: orderItem.basketHashKey,
        __typename: 'EditorCombinationDetailsModel',
        orderHistory: viewerCombinationDetailsData.orderHistory.concat({
          __typename: 'OutputCombinationOrderInfo',
          downloadUrl: orderItem.downloadUrl,
          preview: {
            __typename: 'PreviewModel',
            url: orderItem.preview.url,
            width: orderItem.preview.width,
          },
          orderedAt: new Date().toISOString(),
          templateVersion: orderItem.templateVersion,
          status: OrderItemStatus.Pending,
        } as OutputCombinationOrderInfo),
      });
    }
  }
}
