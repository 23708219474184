import { uniq } from 'ramda';
import { Cache } from '@urql/exchange-graphcache';

import {
  GraphCacheUpdaters,
  OutputCombinationModel,
  WithTypename,
  Query,
} from '../../schema.types';
import { invalidatePaginatedQuery } from './shared';

export const urqlOutputCombinationsHashKeysToIDsMap: {
  [hashKey: string]: string[];
} = {};

export const getUrqlOutputCombinationsCacheIDsByHashKey = (
  combinationHashKey: string,
) => {
  const combinationsIDs =
    urqlOutputCombinationsHashKeysToIDsMap[combinationHashKey] ?? [];

  return combinationsIDs;
};

export const outputCombinationModelUpdaters = {
  id: (parent: WithTypename<OutputCombinationModel>) => {
    // https://github.com/urql-graphql/urql/discussions/2519
    // https://github.com/urql-graphql/urql/issues/762
    // in our case output combination is identified by ID, but sometimes it is useful to read a combination
    // from cache by hashKey so we have this escape hatch
    const hashKey = parent?.hashKey as string;
    const outputCombinationId = parent?.id as string;
    urqlOutputCombinationsHashKeysToIDsMap[hashKey] = uniq([
      ...(urqlOutputCombinationsHashKeysToIDsMap[hashKey] ?? []),
      outputCombinationId,
    ]);

    return parent?.id;
  },
} satisfies GraphCacheUpdaters['OutputCombinationModel'];

export function invalidateAllOutputCombinations(cache: Cache) {
  cache
    .inspectFields('Query')
    .filter((x) => {
      const filedName = x.fieldName as keyof Query;

      return (
        filedName === 'searchSummary' ||
        filedName === 'findOutputCombinationGroups' ||
        filedName === 'allOutputCombinationsByFilters' ||
        filedName === 'outputCombinationGroup'
      );
    })
    .forEach((x) => {
      const fieldName = x.fieldName as keyof Query;

      if (fieldName === 'findOutputCombinationGroups') {
        invalidatePaginatedQuery(cache, fieldName);
      } else {
        cache.invalidate('Query', x.fieldKey);
      }
    });
}
