import { PropsWithChildren, ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import cx from 'clsx';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

import CloseIcon from 'shared/assets/icons/close-icon.svg?react';
import { Theme, WithTheme } from 'aspects/theme';

import styles from './Modal.module.scss';
import { Button, ButtonProps } from '../Button/Button';

type Props<T extends FieldValues> = PropsWithChildren<{
  isOpen: boolean;
  title: string;
  isContentAlwaysScrollable?: boolean;
  theme: Theme;
  formProps?: {
    onSubmit: (data: T) => void;
    form: UseFormReturn<T>;
    shouldOffAutoComplete?: boolean;
  };
  zIndex?: number;
  footer?: ReactNode;
  onClose?(): void;
}>;

const OPEN_TRANSITION_DURATION = 150;

export function Modal<T extends FieldValues>(props: Props<T>) {
  const {
    isOpen,
    onClose,
    children,
    footer,
    title,
    isContentAlwaysScrollable,
    formProps,
    theme,
    zIndex,
  } = props;

  return (
    <Modal.Overlay
      isOpen={isOpen}
      theme={theme}
      onClose={onClose}
      reactModalProps={{
        contentElement: formProps
          ? (contentElementProps, contentChildren) => (
              <div {...contentElementProps}>
                <FormProvider {...formProps.form}>
                  <form
                    onSubmit={formProps.form.handleSubmit(formProps.onSubmit)}
                    autoComplete={
                      formProps.shouldOffAutoComplete ? 'off' : undefined
                    }
                  >
                    {contentChildren}
                  </form>
                </FormProvider>
              </div>
            )
          : undefined,
      }}
      zIndex={zIndex}
    >
      <h3 className={styles.title}>
        {title}
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </h3>
      <div
        className={cx(
          styles.children,
          isContentAlwaysScrollable && styles.alwaysScrollable,
        )}
      >
        {children}
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </Modal.Overlay>
  );
}

type ContentProps = {
  children: ReactNode;
  withBorderBottom?: boolean;
  withoutPaddingBottom?: boolean;
};

Modal.Content = function ModalContent({
  children,
  withBorderBottom,
  withoutPaddingBottom,
}: ContentProps) {
  return (
    <div
      className={cx(
        styles.content,
        withBorderBottom && styles.withBorderBottom,
        withoutPaddingBottom && styles.withoutPaddingBottom,
      )}
    >
      {children}
    </div>
  );
};

Modal.RightAlignedButton = function RightAlignedButton(
  props: Partial<ButtonProps>,
) {
  return (
    <div className={styles.submitWrapper}>
      <Button color="purple" {...props} />
    </div>
  );
};

type ModalOverlayProps = Pick<
  Props<FieldValues>,
  'isOpen' | 'onClose' | 'theme'
> & {
  reactModalProps?: Omit<ReactModalProps, 'isOpen'>;
  zIndex?: number;
  isTransparent?: boolean;
};

Modal.Overlay = function ModalOverlay(
  props: PropsWithChildren<ModalOverlayProps>,
) {
  const {
    isOpen,
    theme,
    onClose,
    reactModalProps,
    children,
    zIndex,
    isTransparent,
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(styles.modal, theme && styles[theme])}
      overlayClassName={{
        base: cx(
          styles.overlay,
          theme && styles[theme],
          isTransparent && styles.transparent,
        ),
        beforeClose: styles.closed,
        afterOpen: styles.open,
      }}
      closeTimeoutMS={OPEN_TRANSITION_DURATION}
      style={{
        overlay: {
          transitionDuration: `${OPEN_TRANSITION_DURATION}ms`,
          zIndex,
        },
      }}
      {...reactModalProps}
    >
      {/* Modal itself is never mounted into WithTheme (as other elements which use portal may do).
      Because ReactModal mounts into DOM only once. So, if any WithTheme, which acts 
      as a parent for ReactModal, unmounts (or re-mounts, unmounting is what matters)
      the Modal is gone from DOM and is never mounted again. We've had a few bugs with disappearing Modals because 
      of this already, it's really annoying and unpredictable. We should not occupy our minds
      with thinking if some WithTheme will unmount and some Modal will be gone because of that.
      So to avoid all of that we just don't mount Modal into WithTheme.
      But Modal's content is wrapped into WithTheme so it can use themed SCSS selectors. */}
      <WithTheme theme={theme} classes={{ root: styles.themeWrapper }}>
        {children}
      </WithTheme>
    </ReactModal>
  );
};
