import { Cache } from '@urql/exchange-graphcache';

import { QueryProductGroupProductOptionsArgs } from 'serverCache/schema.types';

export function invalidateProductOptionsByProductGroupId({
  cache,
  productGroupId,
}: {
  cache: Cache;
  productGroupId: string;
}) {
  cache
    .inspectFields('Query')
    .filter((x) => x.fieldName === 'productGroupProductOptions')
    .forEach((queryField) => {
      const connectionEntityKey = cache.resolve(
        'Query',
        queryField.fieldName,
        queryField.arguments,
      ) as string;

      if (
        (queryField.arguments as QueryProductGroupProductOptionsArgs).filters
          ?.productGroupId === productGroupId
      ) {
        cache.inspectFields(connectionEntityKey).forEach((x) => {
          cache.invalidate(connectionEntityKey, x.fieldName, x.arguments);
        });
      }
    });
}
