import { BemMods, block } from 'bem-cn';

// Creates locators which QA team can use in CSS-selectors for their tests
// to find elements in the UI

export type QALocatorsProp<T extends string> = {
  qaLocators?: Partial<Record<T, Partial<QALocatorProp>>>;
};
export type GetQALocator = ReturnType<typeof makeGetQALocator>;

export type QALocatorProp = {
  'data-test': BemMods & string;
};

export function makeGetQALocator(blockName: string) {
  const b = block(blockName);

  function getQALocator(modifiers: BemMods): QALocatorProp;
  function getQALocator(
    elementName?: string,
    modifiers?: BemMods,
  ): QALocatorProp;
  function getQALocator(x?: string | BemMods, modifiers?: BemMods) {
    if (typeof x === 'object') {
      return {
        'data-test': b(x),
      };
    }

    return {
      'data-test': x ? b(x, modifiers ?? {}) : b(modifiers ?? {}),
    };
  }

  return getQALocator;
}
