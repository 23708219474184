import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TenantsDataVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantsData = { __typename?: 'Query', managedTenants: Array<{ __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> }>, internalUsers: Array<{ __typename?: 'InternalUserModel', id: string, name: string }> };

export type CreateTenantVariables = Types.Exact<{
  input: Types.AddTenantInput;
}>;


export type CreateTenant = { __typename?: 'Mutation', addTenant: { __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> } };

export type EditTenantVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateTenantInput;
}>;


export type EditTenant = { __typename?: 'Mutation', updateTenant: { __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> } };

export type DeleteTenantVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteTenant = { __typename?: 'Mutation', removeTenant: boolean };

export type TenantsPageUser = { __typename?: 'InternalUserModel', id: string, name: string };

export type TenantsPageTenant = { __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> };

export type AssignPMsToTenantVariables = Types.Exact<{
  tenantId: Types.Scalars['UUID'];
  usersIds: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;


export type AssignPMsToTenant = { __typename?: 'Mutation', setTenantProjectManagers: boolean };

export const TenantsPageUser = gql`
    fragment TenantsPageUser on InternalUserModel {
  id
  name
}
    `;
export const TenantsPageTenant = gql`
    fragment TenantsPageTenant on TenantModel {
  domain
  id
  name
  noMatchesImageUrl
  userJourney
  logoUrl
  projectManagers {
    id
    name
  }
}
    `;
export const TenantsDataDocument = gql`
    query TenantsData {
  managedTenants {
    ...TenantsPageTenant
  }
  internalUsers {
    ...TenantsPageUser
  }
}
    ${TenantsPageTenant}
${TenantsPageUser}`;

export function useTenantsData(options?: Omit<Urql.UseQueryArgs<TenantsDataVariables>, 'query'>) {
  return Urql.useQuery<TenantsData, TenantsDataVariables>({ query: TenantsDataDocument, ...options });
};
export const CreateTenantDocument = gql`
    mutation CreateTenant($input: AddTenantInput!) {
  addTenant(input: $input) {
    ...TenantsPageTenant
  }
}
    ${TenantsPageTenant}`;

export function useCreateTenant() {
  return Urql.useMutation<CreateTenant, CreateTenantVariables>(CreateTenantDocument);
};
export const EditTenantDocument = gql`
    mutation EditTenant($id: UUID!, $input: UpdateTenantInput!) {
  updateTenant(id: $id, input: $input) {
    ...TenantsPageTenant
  }
}
    ${TenantsPageTenant}`;

export function useEditTenant() {
  return Urql.useMutation<EditTenant, EditTenantVariables>(EditTenantDocument);
};
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($id: UUID!) {
  removeTenant(id: $id)
}
    `;

export function useDeleteTenant() {
  return Urql.useMutation<DeleteTenant, DeleteTenantVariables>(DeleteTenantDocument);
};
export const AssignPMsToTenantDocument = gql`
    mutation AssignPMsToTenant($tenantId: UUID!, $usersIds: [UUID!]!) {
  setTenantProjectManagers(tenantId: $tenantId, userIds: $usersIds)
}
    `;

export function useAssignPMsToTenant() {
  return Urql.useMutation<AssignPMsToTenant, AssignPMsToTenantVariables>(AssignPMsToTenantDocument);
};