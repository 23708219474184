import * as Types from '../../serverCache/schema.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MsalConfigurationVariables = Types.Exact<{
  input: Types.AuthenticationInput;
}>;


export type MsalConfiguration = { __typename?: 'Query', msalConfiguration: { __typename?: 'MsalConfigurationModel', userJourney: string, clientId: string, instance: string, domain: string } };


export const MsalConfigurationDocument = gql`
    query MsalConfiguration($input: AuthenticationInput!) {
  msalConfiguration(input: $input) {
    userJourney
    clientId
    instance
    domain
  }
}
    `;

/**
 * __useMsalConfiguration__
 *
 * To run a query within a React component, call `useMsalConfiguration` and pass it any options that fit your needs.
 * When your component renders, `useMsalConfiguration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMsalConfiguration({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMsalConfiguration(baseOptions: Apollo.QueryHookOptions<MsalConfiguration, MsalConfigurationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MsalConfiguration, MsalConfigurationVariables>(MsalConfigurationDocument, options);
      }
export function useMsalConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MsalConfiguration, MsalConfigurationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MsalConfiguration, MsalConfigurationVariables>(MsalConfigurationDocument, options);
        }
export type MsalConfigurationHookResult = ReturnType<typeof useMsalConfiguration>;
export type MsalConfigurationLazyQueryHookResult = ReturnType<typeof useMsalConfigurationLazyQuery>;
export type MsalConfigurationQueryResult = Apollo.QueryResult<MsalConfiguration, MsalConfigurationVariables>;