// copied from https://github.com/urql-graphql/urql/blob/main/exchanges/graphcache/src/extras/simplePagination.ts
// and patched in a few places (**)

/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-continue */
import { stringifyVariables } from '@urql/core';
import { Resolver, Variables, NullArray } from '@urql/exchange-graphcache';
import { omit } from 'ramda';

export type CalcHasCurrentPage = (
  requestedRange: { skip: number; limit: number },
  recordsInCacheNumber: number,
) => boolean;

export type MergeMode = 'before' | 'after';

/** Input parameters for the {@link simplePagination} factory. */
export interface PaginationParams {
  /** The name of the field argument used to define the page’s offset. */
  offsetArgument?: string;
  /** The name of the field argument used to define the page’s length. */
  limitArgument?: string;
  /** Flip between forward and backwards pagination.
   *
   * @remarks
   * When set to `'after'`, its default, pages are merged forwards and in order.
   * When set to `'before'`, pages are merged in reverse, putting later pages
   * in front of earlier ones.
   */
  mergeMode?: MergeMode;
}

/** Creates a {@link Resolver} that combines pages of a primitive pagination field.
 *
 * @param options - A {@link PaginationParams} configuration object.
 * @returns the created pagination {@link Resolver}.
 *
 * @remarks
 * `simplePagination` is a factory that creates a {@link Resolver} that can combine
 * multiple lists on a paginated field into a single, combined list for infinite
 * scrolling.
 *
 * Hint: It's not recommended to use this when you can handle infinite scrolling
 * in your UI code instead.
 *
 * @see {@link https://urql.dev/goto/docs/graphcache/local-resolvers#simple-pagination} for more information.
 * @see {@link https://urql.dev/goto/docs/basics/ui-patterns/#infinite-scrolling} for an alternate approach.
 */
export const simplePaginationPatched = ({
  offsetArgument = 'skip',
  limitArgument = 'limit',
  mergeMode = 'after',
  firstPaginationPageNumber,
  calcHasCurrentPage,
}: PaginationParams & {
  firstPaginationPageNumber?: number;
  calcHasCurrentPage?: CalcHasCurrentPage;
} = {}): Resolver<any, any, any> => {
  const compareArgs = (
    fieldArgs: Variables,
    connectionArgs: Variables,
  ): boolean => {
    for (const key in connectionArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue;
      } else if (!(key in fieldArgs)) {
        return false;
      }

      const argA = fieldArgs[key];
      const argB = connectionArgs[key];

      if (
        typeof argA !== typeof argB || typeof argA !== 'object'
          ? argA !== argB
          : stringifyVariables(argA) !== stringifyVariables(argB)
      ) {
        return false;
      }
    }

    for (const key in fieldArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue;
      }
      if (!(key in connectionArgs)) return false;
    }

    return true;
  };

  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info;
    const allFields = cache.inspectFields(entityKey);
    const fieldInfos = allFields.filter((info) => info.fieldName === fieldName);
    const size = fieldInfos.length;
    if (size === 0) {
      return undefined;
    }

    const visited = new Set();
    let result: NullArray<string> = [];
    let prevOffset: number | null = null;

    for (let i = 0; i < size; i++) {
      const { fieldKey, arguments: pageBasedArgs } = fieldInfos[i];
      const args = liftAndReplacePagesWithOffsets({
        args: pageBasedArgs,
        firstPaginationPageNumber,
      }); // (**)

      if (
        args === null ||
        !compareArgs(
          liftAndReplacePagesWithOffsets({
            args: fieldArgs,
            firstPaginationPageNumber,
          }),
          args,
        ) // (**)
      ) {
        continue;
      }

      const links = cache.resolve(entityKey, fieldKey) as string[];
      const currentOffset = args[offsetArgument as 'skip']; // (**)

      if (
        links === null ||
        links.length === 0 ||
        typeof currentOffset !== 'number'
      ) {
        continue;
      }

      const tempResult: NullArray<string> = [];

      for (let j = 0; j < links.length; j++) {
        const link = links[j];
        if (visited.has(link)) continue;
        tempResult.push(link);
        visited.add(link);
      }

      if (
        (!prevOffset || currentOffset > prevOffset) ===
        (mergeMode === 'after')
      ) {
        result = [...result, ...tempResult];
      } else {
        result = [...tempResult, ...result];
      }
      prevOffset = currentOffset;
    }

    const requestedRange = liftAndReplacePagesWithOffsets({
      args: fieldArgs,
      firstPaginationPageNumber,
    });

    const hasCurrentPage = calcHasCurrentPage // (**)
      ? calcHasCurrentPage(requestedRange, result.length)
      : cache.resolve(entityKey, fieldName, fieldArgs);

    if (hasCurrentPage) {
      return result.slice(0, requestedRange.skip + requestedRange.limit); // (**)
    }
    if (!(info as any).store.schema) {
      return undefined;
    }

    // https://formidable.com/open-source/urql/docs/graphcache/local-resolvers/#causing-cache-misses-and-partial-misses
    // However, sometimes we may want a resolver to return a result, while still sending a GraphQL API request in the background to update our resolver’s values. To achieve this we can update the info.partial field.
    info.partial = true;
    return result;
  };
};

type PagePaginationArgs = { page?: number; pageSize?: number };
type SourceOfPageArgs =
  | ({ filter?: PagePaginationArgs | null } & PagePaginationArgs)
  | null;
export function liftAndReplacePagesWithOffsets<Args extends SourceOfPageArgs>({
  args,
  errorWarningPrefix,
  firstPaginationPageNumber = 1,
}: {
  args?: Args;
  errorWarningPrefix?: string;
  firstPaginationPageNumber?: number;
}): Omit<Args, 'page' | 'pageSize'> & { skip: number; limit: number } {
  if (args) {
    const pageArgs = extractPageArgs(args);
    const { pageSize } = pageArgs;
    const page = pageArgs.page - firstPaginationPageNumber;
    const offsetArgs = {
      skip: page * pageSize,
      limit: pageSize,
    };

    return {
      ...offsetArgs,
      ...omit(['pageSize', 'page'], {
        ...args,
        /*
          - simple pagination helper doesn't understand anything except skip&limit params. since there's no way to map our page & pageSize to skip & limit args via function arguments we patch the pagination helper.
          - the line below: override and make page and pageSize params constant 1) prevent the query from reacting to them 2) because their function is mapped and will be performed via skip and limit params that the simplePagination helper understands
        */
        filter: { page: 0, pageSize: 0 }, // ignore and make constant
      }),
    };
  }
  throw new Error(
    `${errorWarningPrefix}: page size and page pagination args are not provided!`,
  );
}

function extractPageArgs<Args extends SourceOfPageArgs>(
  args: Args,
  errorWarningPrefix?: string,
): { page: number; pageSize: number } {
  if (args) {
    const { filter } = args;
    if (args.page !== undefined && args.pageSize !== undefined) {
      return {
        page: args.page,
        pageSize: args.pageSize,
      };
    }
    if (filter) {
      const { page, pageSize } = filter;
      if (page !== undefined && pageSize !== undefined) {
        return {
          page,
          pageSize,
        };
      }
    }
  }
  throw new Error(
    `${errorWarningPrefix}: page size and page pagination args are not provided!`,
  );
}
