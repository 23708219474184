import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BrandsPageDataVariables = Types.Exact<{ [key: string]: never; }>;


export type BrandsPageData = { __typename?: 'Query', brands: Array<{ __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string }> };

export type CreateBrandVariables = Types.Exact<{
  input: Types.AddBrandInput;
}>;


export type CreateBrand = { __typename?: 'Mutation', addBrand: { __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string } };

export type UpdateBrandVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateBrandInput;
}>;


export type UpdateBrand = { __typename?: 'Mutation', updateBrand: { __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string } };

export type DeleteBrandVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteBrand = { __typename?: 'Mutation', deleteBrand: boolean };

export type BrandsPageBrand = { __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string };

export const BrandsPageBrand = gql`
    fragment BrandsPageBrand on BrandModel {
  id
  name
  color
  logoUrl
}
    `;
export const BrandsPageDataDocument = gql`
    query BrandsPageData {
  brands {
    ...BrandsPageBrand
  }
}
    ${BrandsPageBrand}`;

export function useBrandsPageData(options?: Omit<Urql.UseQueryArgs<BrandsPageDataVariables>, 'query'>) {
  return Urql.useQuery<BrandsPageData, BrandsPageDataVariables>({ query: BrandsPageDataDocument, ...options });
};
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: AddBrandInput!) {
  addBrand(input: $input) {
    ...BrandsPageBrand
  }
}
    ${BrandsPageBrand}`;

export function useCreateBrand() {
  return Urql.useMutation<CreateBrand, CreateBrandVariables>(CreateBrandDocument);
};
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($id: UUID!, $input: UpdateBrandInput!) {
  updateBrand(id: $id, input: $input) {
    ...BrandsPageBrand
  }
}
    ${BrandsPageBrand}`;

export function useUpdateBrand() {
  return Urql.useMutation<UpdateBrand, UpdateBrandVariables>(UpdateBrandDocument);
};
export const DeleteBrandDocument = gql`
    mutation DeleteBrand($id: UUID!) {
  deleteBrand(id: $id)
}
    `;

export function useDeleteBrand() {
  return Urql.useMutation<DeleteBrand, DeleteBrandVariables>(DeleteBrandDocument);
};