import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductGroupsDataVariables = Types.Exact<{ [key: string]: never; }>;


export type ProductGroupsData = { __typename?: 'Query', productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> }> };

export type ProductGroupDataVariables = Types.Exact<{
  productGroupId: Types.Scalars['UUID'];
}>;


export type ProductGroupData = { __typename?: 'Query', productGroupById: { __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> } };

export type CreateProductGroupVariables = Types.Exact<{
  input: Types.ProductGroupInput;
}>;


export type CreateProductGroup = { __typename?: 'Mutation', addProductGroup: { __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> } };

export type UpdateProductGroupVariables = Types.Exact<{
  input: Types.ProductGroupInput;
  id: Types.Scalars['UUID'];
}>;


export type UpdateProductGroup = { __typename?: 'Mutation', updateProductGroup: { __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> } };

export type DeleteProductGroupVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteProductGroup = { __typename?: 'Mutation', removeProductGroup: boolean };

export type ProductGroupsProductGroup = { __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> };

export const ProductGroupsProductGroup = gql`
    fragment ProductGroupsProductGroup on ProductGroupModel {
  id
  name
  products {
    id
    name
  }
  users {
    id
    name
  }
}
    `;
export const ProductGroupsDataDocument = gql`
    query ProductGroupsData {
  productGroups {
    ...ProductGroupsProductGroup
  }
}
    ${ProductGroupsProductGroup}`;

export function useProductGroupsData(options?: Omit<Urql.UseQueryArgs<ProductGroupsDataVariables>, 'query'>) {
  return Urql.useQuery<ProductGroupsData, ProductGroupsDataVariables>({ query: ProductGroupsDataDocument, ...options });
};
export const ProductGroupDataDocument = gql`
    query ProductGroupData($productGroupId: UUID!) {
  productGroupById(productGroupId: $productGroupId) {
    ...ProductGroupsProductGroup
  }
}
    ${ProductGroupsProductGroup}`;

export function useProductGroupData(options: Omit<Urql.UseQueryArgs<ProductGroupDataVariables>, 'query'>) {
  return Urql.useQuery<ProductGroupData, ProductGroupDataVariables>({ query: ProductGroupDataDocument, ...options });
};
export const CreateProductGroupDocument = gql`
    mutation CreateProductGroup($input: ProductGroupInput!) {
  addProductGroup(input: $input) {
    ...ProductGroupsProductGroup
  }
}
    ${ProductGroupsProductGroup}`;

export function useCreateProductGroup() {
  return Urql.useMutation<CreateProductGroup, CreateProductGroupVariables>(CreateProductGroupDocument);
};
export const UpdateProductGroupDocument = gql`
    mutation UpdateProductGroup($input: ProductGroupInput!, $id: UUID!) {
  updateProductGroup(input: $input, productGroupId: $id) {
    ...ProductGroupsProductGroup
  }
}
    ${ProductGroupsProductGroup}`;

export function useUpdateProductGroup() {
  return Urql.useMutation<UpdateProductGroup, UpdateProductGroupVariables>(UpdateProductGroupDocument);
};
export const DeleteProductGroupDocument = gql`
    mutation DeleteProductGroup($id: UUID!) {
  removeProductGroup(productGroupId: $id)
}
    `;

export function useDeleteProductGroup() {
  return Urql.useMutation<DeleteProductGroup, DeleteProductGroupVariables>(DeleteProductGroupDocument);
};