/* avoid issues when a user opens a specific link of the application
 then gets auto logged out (because token is expired)
 and then, after successful login, gets redirected to the default app page
 instead of getting redirected to the page he opened initially, before auto log out happened
*/

const REDIRECT_URL_KEY = 'REDIRECT_URL';

export function saveURLBeforeAutoLogout() {
  const url = `${window.location.pathname}${window.location.search}`;

  sessionStorage.setItem(REDIRECT_URL_KEY, url);
}

export function getURLSavedBeforeAutoLogout() {
  return sessionStorage.getItem(REDIRECT_URL_KEY);
}

export function clearURLSavedBeforeAutoLogout() {
  return sessionStorage.removeItem(REDIRECT_URL_KEY);
}
