export const API_URL = `${import.meta.env.VITE_API_ENDPOINT}graphql/`;
// current type for template input category in schema is just a string
// this constant includes the exact options
// in future it should be typed as en Enum, but right now it isn't because categories list is not final
export const TEMPLATE_INPUT_CATEGORIES = {
  product: 'product_select',
  productLayout: 'product_layout',
  info: 'product_info',
  usage: 'product_usage',
  environment: 'template_environment',
  text: 'template_text',
  graphics: 'template_graphics',
  templateLayout: 'template_layout',
  animation: 'template_animation',
  audio: 'template_audio',
  ai: 'template_ai',
  templateScene1: 'template_scene1',
  templateScene2: 'template_scene2',
  templateScene3: 'template_scene3',
  templateScene4: 'template_scene4',
  templateScene5: 'template_scene5',
};
